@use 'functions' as *;
@use "constants" as constant;

$popover-border-color: #bfbfbf;
$popover-drop-down-border-color: #C8C8C9;
$leaf-border-color: #a3a3a3;
$tooltip-background-color: #1a1a1a;
$light-border-color: #dee2e9;
$dropdown-actions-list-border-color: #c1c4e1;

.cdk-overlay-container {
  z-index: 50000 !important;

  .cdk-overlay-pane {
    transition-property: left, top;
    transition-duration: 100ms;
    transition-timing-function: ease;
  }
}

.cdk-overlay-pane {
  transform: translateX(var(--translateX)) translateY(var(--translateY));
}

.sat-popover-container {
  background-color: white;
  border: 1px solid $popover-border-color;
  font-size: rem(13);
  border-radius: 2px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &.dropdown-filter {
    box-shadow: constant.$gc-dialog-box-shadow;
    border-color: $popover-drop-down-border-color;
    border-radius: rem(4);
  }

  &.data-popover {
    &:before {
      content: '';
      display: block;
      position: absolute;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border: var(--border-before) solid transparent;
      pointer-events: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border: var(--border-after) solid transparent;
      pointer-events: none;
    }

    &-top {
      &:before {
        left: var(--left);
        bottom: 100%;
        border-bottom-color: $leaf-border-color;
      }

      &:after {
        left: var(--left);
        bottom: 100%;
        border-bottom-color: white;
      }
    }

    &-bottom {
      &:before {
        left: var(--left);
        top: 100%;
        border-top-color: $leaf-border-color;
      }

      &:after {
        left: var(--left);
        top: 100%;
        border-top-color: white;
      }
    }

    &-right {
      &:before {
        top: var(--top);
        left: 100%;
        transform: translateY(-50%);
        border-left-color: $leaf-border-color;
      }

      &:after {
        top: var(--top);
        left: 100%;
        transform: translateY(-50%);
        border-left-color: white;
      }
    }

    &-left {
      &:before {
        top: var(--top);
        right: 100%;
        transform: translateY(-50%);
        border-right-color: $leaf-border-color;
      }

      &:after {
        top: var(--top);
        right: 100%;
        transform: translateY(-50%);
        border-right-color: white;
      }
    }
  }

  &.default-popover-on-hover {
    border-radius: 5px;
    padding: rem(13);
    border: 1px solid #bec3c7;
  }

  &.tooltip {
    background-color: $tooltip-background-color;
    color: #fff;
    font-size: 0.9rem;
    border-radius: 4px;
    padding: 0.23rem 0.6rem;
    box-shadow: none;
    border: none;

    p {
      color: #fff;
    }

    &.copy-tooltip {
      padding: rem(12);
    }

    &.data-popover {
      &-top {
        &:before,
        &:after {
          border-bottom-color: $tooltip-background-color;
        }
      }

      &-bottom {
        &:before,
        &:after {
          border-top-color: $tooltip-background-color;
        }
      }

      &-right {
        &:before,
        &:after {
          border-left-color: $tooltip-background-color;
        }
      }

      &-left {
        &:before,
        &:after {
          border-right-color: $tooltip-background-color;
        }
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.gc-dropdown {
  .sat-popover-container {
    background-color: white;
    border: 1px solid $popover-border-color;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: rem(13);
    padding: 0;
    border-radius: 2px;
  }
}

.popover-light-border {
  &.sat-popover-container {
    border: 1px solid #e5e6eb;
    border-radius: rem(4);
    box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, 0.1);
  }
}

.sat-popover-container {
  &.dropdown-actions-list {
    border: 1px solid $dropdown-actions-list-border-color;
    border-radius: rem(3);
    min-width: rem(80);
  }
}

.round-corners-popover {
  &.sat-popover-container {
    padding: unset;
    border-radius: rem(8);
    border: 1px solid $light-border-color;
  }

  &.data-popover {
    &-top {
      &:before {
        border-bottom-color: $light-border-color;
      }

      &:after {
        border-bottom-color: white;
      }
    }

    &-bottom {
      &:before {
        border-top-color: $light-border-color;
      }

      &:after {
        border-top-color: white;
      }
    }
  }
}

.popover-table-operations {
  &.sat-popover-container {
    border-radius: 4px;
    margin-top: rem(5) !important;
    border: 1px solid #c1c4e1;
  }
}

.header-dropdown-overlay {
  &.sat-popover-container {
    color: #6B6C7E;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
  }
}

.dashboard-group {
  &.sat-popover-container {
    border: none;
    background: #180840;
    box-shadow: inset 0px -1px 0px rgba(246, 246, 251, 0.6);
    border-radius: 0 10px 10px 0;
  }
}

.dashboard-group {
  &.sat-popover-container {
    border: none;
    background: #180840;
    box-shadow: inset 0px -1px 0px rgba(246, 246, 251, 0.6);
    border-radius: 0 10px 10px 0;
  }
}

.white-tooltip {
  &.sat-popover-container {
    background-color: white;
    color: #000000;
    border-radius: 2px;
    padding: 8px 12px;
    box-shadow: 0px 4px 14px rgb(0 0 0 / 10%);

    p {
      color: #000000;
      font-size: 14px;
      line-height: 16px;
    }

    &.data-popover {
      &-top {
        &:before,
        &:after {
          border-bottom-color: white;
        }
      }

      &-bottom {
        &:before,
        &:after {
          border-top-color: white;
        }
      }

      &-right {
        &:before,
        &:after {
          border-left-color: white;
        }
      }

      &-left {
        &:before,
        &:after {
          border-right-color: white;
        }
      }
    }
  }
}

.bold-title {
  font-weight: bold;
}
