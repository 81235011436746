@use './clear-button' as *;
@use 'functions' as fn;
@use '../theming' as theming;

@mixin default-button {
  @include clear-button();

  cursor: pointer;
  border: 1px solid #c1c4e1;
  background-color: #fff;
  padding: fn.rem(8) fn.rem(12);
  border-radius: fn.rem(4);
  font-size: fn.rem(14);
  line-height: fn.rem(14);
  height: fn.rem(32);
  display: flex;
  align-items: center;
  color: theming.get-grey-color(800);
  fill: theming.get-grey-color(800);

  gc-icon {
    margin-right: fn.rem(5);

    &[svgIcon="expand-more"], &[svgIcon="expand-less"] {
      margin-left: fn.rem(10);
      margin-right: 0;
      color: #09011d;
      font-size: fn.rem(11);
      vertical-align: middle;
    }
  }

  &:hover {
    background-color: #f5fbff;
    border: 1px solid #a0b0d4;
  }

  &:active:not([disabled]) {
    background-color: #cacaca;
  }

  &[disabled] {
    color: #979797;
    fill: #979797;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    cursor: unset;
  }

  &.button-spacing {
    margin-right: fn.rem(7);
  }

  .button-spacing & {
    margin-right: fn.rem(7);
  }

  div.gc-popover-open & {
    border-color: #adadad;
    background-color: #e6e6e6;
    box-shadow: inset 0 3px 5px #0000002b;

    &:hover {
      background-color: #cacaca;
    }
  }
}
