@import 'ag-grid-enterprise/styles/ag-grid.css';
@import './lib/grid';
@import 'ag-grid-enterprise/styles/ag-theme-balham.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';
@import './lib/components/overrides';

@import './lib/scrollbar';
@import './lib/global';
@import './lib/live-marker';
@import './lib/sat-popover';
@import './lib/buttons';
@import './lib/icon';
@import './lib/form_erros';
