$breakpoint-mid-to-high-resolution: 1280px;

$main-header-height: 48px;
$side-navbar-width: 80px;
$minify-bezier-ease-out: cubic-bezier(0.2, 0, 0, 1);
$minify-bezier-ease-in: cubic-bezier(0.2, 0, 0, 1);
$sharp-bezier: cubic-bezier(0.22, 0.61, 0.36, 1);

$base-fontsize-num: 13;
$base-fontsize: #{$base-fontsize-num}px;
$base-font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$color-primary: #7400f3;
$white: #ffffff;
$black: #000000;
$red-danger-color: #a94442;

$heading-font-weight: 300;

$code-monospace-font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
$gc-dialog-box-shadow: 0px 4px 14px rgb(0 0 0 / 10%);
