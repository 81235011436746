@font-face {
  font-family: 'graph-entities';
  src: url('../font/graph-entities.eot?3463745');
  src: url('../font/graph-entities.eot?3463745#iefix') format('embedded-opentype'),
       url('../font/graph-entities.woff2?3463745') format('woff2'),
       url('../font/graph-entities.woff?3463745') format('woff'),
       url('../font/graph-entities.ttf?3463745') format('truetype'),
       url('../font/graph-entities.svg?3463745#graph-entities') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'graph-entities';
    src: url('../font/graph-entities.svg?3463745#graph-entities') format('svg');
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8-imac:before,
.icons8-imac-2:before,
.icons8-laptop:before,
.icons8-laptop-2:before,
.icons8-add-folder:before,
.icons8-add-folder-filled:before,
.icons8-folder:before,
.icons8-open-folder:before,
.icons8-open-folder-filled:before,
.icons8-cloud:before,
.icons8-cloud-filled:before,
.icons8-database:before,
.icons8-database-filled:before,
.icons8-settings:before,
.icons8-settings-filled:before,
.icons8-console:before,
.icons8-console-2:before,
.icons8-code:before,
.icons8-code-2:before,
.icons8-docker:before,
.icons8-docker-filled:before,
.icons8-goal:before,
.icons8-goal-filled:before,
.icons8-honey-filled:before,
.icons8-full-moon:before,
.icons8-full-moon-filled:before,
.icons8-mind-map-filled:before,
.icons8-mind-map:before,
.icons8-layers-filled:before,
.icons8-price-tag:before,
.icons8-price-tag-filled:before,
.icons8-template:before,
.icons8-template-filled:before,
.icons8-console-3:before,
.icons8-server:before,
.icons8-server-filled:before,
.icons8-globe:before,
.icons8-shield-filled:before,
.icons8-shield:before,
.icons8-restriction-shield-filled:before,
.icons8-restriction-shield:before,
.icons8-delete-shield-filled:before,
.icons8-delete-shield:before,
.icons8-honey:before,
.icons8-question-mark-filled:before,
.icons8-folder-filled:before,
.icons8-domain:before,
.icons8-opera-glasses-filled:before,
.icons8-circle:before,
.icons8-four-squares:before,
.icons8-four-squares-2:before,
.icons8-price-label:before,
.icons8-android-os:before,
.icons8-lambda:before,
.icons8-lambda-filled:before,
.icons8-artificial-intelligence:before,
.icons8-settings-2:before,
.icons8-bitbucket:before,
.icons8-file:before,
.icons8-api:before,
.icons8-google-compute-engine:before,
.icons8-load-balancer:before,
.icons8-google-cloud-platform:before,
.icons8-cloud-storage:before,
.icons8-cloud-development:before,
.icons8-cloud-database:before,
.icons8-renew:before,
.icons8-circle-2:before,
.icons8-frequency-f:before,
.icons8-chevron-right:before,
.icons8-add-tag:before,
.icons8-settings-3:before,
.icons8-settings-4:before,
.icons8-automatic:before,
.icons8-gear:before,
.icons8-network-log:before,
.icons8-info-icon:before,
.k8s-pod:before,
.k8s-service:before,
.k8s-kubernetes:before,
.icons8-rss:before,
.gc-dashed-horizontal-line:before,
.gc-open-in-new-window:before,
.icons8,
[data-icons8]:before {
  display: inline-block;
  font-family: "graph-entities";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-imac:before { content: '\f100'; } /* '' */
.icons8-imac-2:before { content: '\f101'; } /* '' */
.icons8-laptop:before { content: '\f102'; } /* '' */
.icons8-laptop-2:before { content: '\f103'; } /* '' */
.icons8-add-folder:before { content: '\f104'; } /* '' */
.icons8-add-folder-filled:before { content: '\f105'; } /* '' */
.icons8-folder:before { content: '\f106'; } /* '' */
.icons8-open-folder:before { content: '\f107'; } /* '' */
.icons8-open-folder-filled:before { content: '\f108'; } /* '' */
.icons8-cloud:before { content: '\f109'; } /* '' */
.icons8-cloud-filled:before { content: '\f10a'; } /* '' */
.icons8-database:before { content: '\f10b'; } /* '' */
.icons8-database-filled:before { content: '\f10c'; } /* '' */
.icons8-settings:before { content: '\f10d'; } /* '' */
.icons8-settings-filled:before { content: '\f10e'; } /* '' */
.icons8-console:before { content: '\f10f'; } /* '' */
.icons8-console-2:before { content: '\f110'; } /* '' */
.icons8-code:before { content: '\f111'; } /* '' */
.icons8-code-2:before { content: '\f112'; } /* '' */
.icons8-docker:before { content: '\f113'; } /* '' */
.icons8-docker-filled:before { content: '\f114'; } /* '' */
.icons8-goal:before { content: '\f115'; } /* '' */
.icons8-goal-filled:before { content: '\f116'; } /* '' */
.icons8-honey-filled:before { content: '\f117'; } /* '' */
.icons8-full-moon:before { content: '\f118'; } /* '' */
.icons8-full-moon-filled:before { content: '\f119'; } /* '' */
.icons8-mind-map-filled:before { content: '\f11a'; } /* '' */
.icons8-mind-map:before { content: '\f11b'; } /* '' */
.icons8-layers-filled:before { content: '\f11c'; } /* '' */
.icons8-price-tag:before { content: '\f11d'; } /* '' */
.icons8-price-tag-filled:before { content: '\f11e'; } /* '' */
.icons8-template:before { content: '\f11f'; } /* '' */
.icons8-template-filled:before { content: '\f120'; } /* '' */
.icons8-console-3:before { content: '\f121'; } /* '' */
.icons8-server:before { content: '\f122'; } /* '' */
.icons8-server-filled:before { content: '\f123'; } /* '' */
.icons8-globe:before { content: '\f124'; } /* '' */
.icons8-shield-filled:before { content: '\f125'; } /* '' */
.icons8-shield:before { content: '\f126'; } /* '' */
.icons8-restriction-shield-filled:before { content: '\f127'; } /* '' */
.icons8-restriction-shield:before { content: '\f128'; } /* '' */
.icons8-delete-shield-filled:before { content: '\f129'; } /* '' */
.icons8-delete-shield:before { content: '\f12a'; } /* '' */
.icons8-honey:before { content: '\f12b'; } /* '' */
.icons8-question-mark-filled:before { content: '\f12c'; } /* '' */
.icons8-folder-filled:before { content: '\f12d'; } /* '' */
.icons8-domain:before { content: '\f12e'; } /* '' */
.icons8-opera-glasses-filled:before { content: '\f12f'; } /* '' */
.icons8-circle:before { content: '\f130'; } /* '' */
.icons8-four-squares:before { content: '\f131'; } /* '' */
.icons8-four-squares-2:before { content: '\f132'; } /* '' */
.icons8-price-label:before { content: '\f133'; } /* '' */
.icons8-android-os:before { content: '\f134'; } /* '' */
.icons8-lambda:before { content: '\f135'; } /* '' */
.icons8-lambda-filled:before { content: '\f136'; } /* '' */
.icons8-artificial-intelligence:before { content: '\f137'; } /* '' */
.icons8-settings-2:before { content: '\f138'; } /* '' */
.icons8-bitbucket:before { content: '\f139'; } /* '' */
.icons8-file:before { content: '\f13a'; } /* '' */
.icons8-api:before { content: '\f13b'; } /* '' */
.icons8-google-compute-engine:before { content: '\f13c'; } /* '' */
.icons8-load-balancer:before { content: '\f13d'; } /* '' */
.icons8-google-cloud-platform:before { content: '\f13e'; } /* '' */
.icons8-cloud-storage:before { content: '\f13f'; } /* '' */
.icons8-cloud-development:before { content: '\f140'; } /* '' */
.icons8-cloud-database:before { content: '\f141'; } /* '' */
.icons8-renew:before { content: '\f143'; } /* '' */
.icons8-circle-2:before { content: '\f144'; } /* '' */
.icons8-frequency-f:before { content: '\f147'; } /* '' */
.icons8-chevron-right:before { content: '\f148'; } /* '' */
.icons8-add-tag:before { content: '\f149'; } /* '' */
.icons8-settings-3:before { content: '\f14a'; } /* '' */
.icons8-settings-4:before { content: '\f14b'; } /* '' */
.icons8-automatic:before { content: '\f14c'; } /* '' */
.icons8-gear:before { content: '\f14d'; } /* '' */
.icons8-network-log:before { content: '\e851'; }
.icons8-info-icon:before { content: '\e852'; }
.k8s-pod:before { content: '\e84b'; }
.k8s-service:before { content: '\e84c'; }
.k8s-kubernetes:before { content: '\e84f'; }
.icons8-rss:before { content: '\e84d'; }
.gc-dashed-horizontal-line:before { content: '\e84e'; transform-origin: center; transform: scaleX(0.84) }
.gc-open-in-new-window:before { content: '\e850'; }
