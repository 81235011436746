@use 'theming' as theming;
@use 'functions' as fn;
@use "constants" as constant;

$gc-dialog-border-color: #dee2e6;
$gc-dialog-padding: 1rem 1.5rem;
$gc-dialog-background-color: #fff;
$gc-dialog-header-background-color: theming.get-blue-purple-color(150);
$gc-dialog-border-radius: fn.rem(4);

$error-message-border-color: #fdafbb;
$error-message-background-color: #fbe4e8;
$error-message-icon-color: #b7372d;
$text-black: #09011d;

@mixin gc-dialog-panel {
  padding: fn.rem(16);
}

.gc-dialog-content {
  background-color: $gc-dialog-background-color;
  border-radius: $gc-dialog-border-radius;
  box-shadow: constant.$gc-dialog-box-shadow;
  width: fn.rem(600);

  &-header {
    @include gc-dialog-panel;
    font-weight: 300;
    font-size: 1.6rem;
    background: $gc-dialog-header-background-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      line-height: fn.rem(24);
      font-size: fn.rem(20);
      font-weight: 400;
    }

    .header-close-button {
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      cursor: pointer;
      display: flex;

      gc-icon {
        height: fn.rem(24);
        width: fn.rem(24);
        fill: theming.get-color(grey, 750)
      }
    }
  }

  &-body {
    @include gc-dialog-panel;
  }

  &-footer {
    @include gc-dialog-panel;
    height: fn.rem(64);
    padding: fn.rem(8) fn.rem(16);
    border-top: solid 1px $gc-dialog-border-color;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-left: fn.rem(12);
      width: fit-content;
    }

    .error-message {
      max-height: fn.rem(48);
      padding: fn.rem(14);
      display: flex;
      flex-direction: row;
      background-color: $error-message-background-color;
      border: 1px solid $error-message-border-color;
      border-radius: fn.rem(4);
      color: $text-black;
      line-height: fn.rem(16);
      align-items: center;

      .title {
        font-weight: 500;
        margin-right: fn.rem(8);
      }

      gc-icon {
        width: fn.rem(18);
        height: fn.rem(18);
        fill: $error-message-icon-color;
        margin: 0 fn.rem(10) 0 0;
      }
    }
  }
}
