@use './functions' as fn;
@use './mixins' as mx;
@use './constants' as const;
@use './theming' as theming;
@forward './input';
@forward './dialog-content';
@forward './input_checkbox';
@forward './bulk-inline-alert';
@forward './input_radio';

html,
body {
  height: 100%;
  font-size: const.$base-fontsize;
  font-family: const.$base-font-family;
}
body {
  margin: 0;
  line-height: 1.42857143;
  overflow-y: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body,
div,
section,
aside,
form,
input,
form {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;

  &:active,
  &:visited,
  &:-webkit-any-link {
    color: theming.get-text-color(link);
  }

  &:hover {
    text-decoration: underline;
  }
}

button,
input {
  font-family: const.$base-font-family;
}

.spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100% !important;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.padding-0 {
  padding: 0 !important;
}

.small-text-1 {
  font-size: 85%;
}

.user-menu-overlay {
  transform: translateX(fn.rem(-1)) translateY(fn.rem(1)) !important;
}

.filters-popover {
  min-width: 20rem;
  max-width: 20rem;
}

.z-index-1050 {
  z-index: 1050;

  .section-message {
    opacity: 0.32;
  }
}

.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.mat-dialog {
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.show-backdrop {
  opacity: 0.5;
  z-index: 1040;
}
.show-mat-dialog-backdrop {
  opacity: 0.32;
}

input[type='checkbox'] {
  margin-right: 0.4rem;
  vertical-align: top;
}

input[type='checkbox'] + label {
  user-select: none;
}

*[class^='fa-'],
*[class*=' fa-'] {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.inline-block {
  display: inline-block;
}

.default-cursor {
  cursor: default !important;
}

gc-icon {
  width: fn.rem(16);
  height: fn.rem(16);
}

.gc-underlined {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #6e6e6e;
}
