@mixin clear-button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  display: inline;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  user-select: none;
  cursor: pointer;
}
