@use './functions' as fn;

input[type='checkbox'] {
    accent-color: #4B70D0;
    width: fn.rem(16);
    height: fn.rem(16);
    cursor: pointer;
    appearance: none;
    border-radius: fn.rem(2.5);
    outline: none;
}

input[type='checkbox']:not(:checked):not(:indeterminate) {
    
    background-color: #FFFFFF;
    border: solid fn.rem(1) #9E9FA3;
    content: none;
    
    &:hover {
        border-color: #0075FF;
    }
    &:focus {
        border-color: #9E9FA3; 
        box-shadow: 0 0 0 fn.rem(2) #D3DCF3;
    }
    &:disabled {
        border-color: #B6B3B3;
        background-color: #EBEBEB;
    }
}

input[type='checkbox']:checked:not(:indeterminate) {
    background-color: #4B70D0;
    &::after {
        content: "";
        display: inline-block;
        height: fn.rem(4.24999999999);
        width: fn.rem(11);
        border-left: 2px solid;
        border-bottom: 2px solid;
        position: relative;
        top: fn.rem(-4);
        left: fn.rem(2.5);
        border-color: #FFFFFF;
        transform: rotate(-47deg) skew(357deg);
    }
    &:hover {
        background-color: #3D65CC;
    }
    &:focus {
        background-color: #4B70D0;
        box-shadow: 0 0 0 fn.rem(2) #D3DCF3;
    }
    &:disabled {
        background-color: #B6B3B3;
    }
    &:disabled::after {
        border-color: #EBEBEB;
    }
}

input[type='checkbox']:indeterminate:not(:checked) {
    background-color: #4B70D0;
    &::after {
        content: "";
        display: inline-block;
        height: fn.rem(4.24999999999);
        width: fn.rem(10);
        border-bottom: fn.rem(2) solid;
        position: relative;
        top: fn.rem(-4);
        left: fn.rem(3);
        border-color: #FFFFFF;
    }
    &:hover {
        background-color: #3D65CC;
    }
    &:focus {
        background-color: #4B70D0;
        box-shadow: 0 0 0 fn.rem(2) #D3DCF3;
    }
    &:disabled {
        background-color: #B6B3B3;
    }
    &:disabled::after {
        border-color: #EBEBEB;
    }
}
