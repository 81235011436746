/*!
 * Icon Font general2
 * Made with love by Icons8 [ https://icons8.com/ ] using webfont [ https://github.com/itgalaxy/webfont ]
 *
 * Contacts:
 *    [ https://icons8.com/contact ]
 *
 * Follow Icon8 on
 *    Twitter [ https://twitter.com/icons_8 ]
 *    Facebook [ https://www.facebook.com/Icons8 ]
 *    Google+ [ https://plus.google.com/+Icons8 ]
 *    GitHub [ https://github.com/icons8 ]
 */

@font-face {
  font-family: "general2";
  src: url("../fonts/general2.eot");
  src: url("../fonts/general2.eot?#iefix") format("embedded-opentype"),
  url("../fonts/general2.woff2") format("woff2"),
  url("../fonts/general2.woff") format("woff"),
  url("../fonts/general2.ttf") format("truetype"),
  url("../fonts/general2.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "general2";
    src: url("../fonts/general2.svg#general2") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8-attention:before,
.icons8-statistics:before,
.icons8-combo-chart:before,
.icons8-test-tube:before,
.icons8-thin-test-tube:before,
.icons8-electronics:before,
.icons8-electronics-filled:before,
.icons8-system-task:before,
.icons8-virtual-machine-2:before,
.icons8-clouds:before,
.icons8-clouds-filled:before,
.icons8-cloud:before,
.icons8-plugin:before,
.icons8-system-report-filled:before,
.icons8-high-priority-filled:before,
.icons8-high-priority:before,
.icons8-system-report:before,
.icons8-checkmark:before,
.icons8-ok:before,
.icons8-waste-filled:before,
.icons8-waste:before,
.icons8-delete-filled:before,
.icons8-copy:before,
.icons8-copy-filled:before,
.icons8-plus-math:before,
.icons8-length:before,
.icons8-length-filled:before,
.icons8-clone:before,
.icons8-clone-filled:before,
.icons8-rotate-left:before,
.icons8-unlock:before,
.icons8-private-2-filled:before,
.icons8-pill:before,
.icons8-doctors-bag:before,
.icons8-horizontal-settings-mixer-filled:before,
.icons8-manual-filled:before,
.icons8-no-entry-filled:before,
.icons8-ethernet-off:before,
.icons8-versions:before,
.icons8-connected:before,
.icons8-stop-filled:before,
.icons8-delete:before,
.icons8-high-importance:before,
.icons8-circled-up:before,
.icons8-brick-wall-filled:before,
.icons8-cancel:before,
.icons8-computer-monitor:before,
.icons8-computer-monitor-2:before,
.icons8-split-files:before,
.icons8-merge-files:before,
.icons8-certificate:before,
.icons8-windows-client:before,
.icons8-horizontal-line:before,
.icons8-frequency-f:before,
.icons8-up-right:before,
.icons8-down-right:before,
.icons8-binoculars:before,
.icons8-tags:before,
.icons8,
[data-icons8]:before {
  display: inline-block;
  font-family: "general2";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


.icons8-attention:before {content: "\f100";}
.icons8-statistics:before {content: "\f101";}
.icons8-combo-chart:before {content: "\f102";}
.icons8-test-tube:before {content: "\f103";}
.icons8-thin-test-tube:before {content: "\f104";}
.icons8-electronics:before {content: "\f105";}
.icons8-electronics-filled:before {content: "\f106";}
.icons8-system-task:before {content: "\f107";}
.icons8-virtual-machine-2:before {content: "\f108";}
.icons8-clouds:before {content: "\f109";}
.icons8-clouds-filled:before {content: "\f10a";}
.icons8-cloud:before {content: "\f10b";}
.icons8-plugin:before {content: "\f10c";}
.icons8-system-report-filled:before {content: "\f10d";}
.icons8-high-priority-filled:before {content: "\f10e";}
.icons8-high-priority:before {content: "\f10f";}
.icons8-system-report:before {content: "\f110";}
.icons8-checkmark:before {content: "\f111";}
.icons8-ok:before {content: "\f112";}
.icons8-waste-filled:before {content: "\f113";}
.icons8-waste:before {content: "\f114";}
.icons8-delete-filled:before {content: "\f115";}
.icons8-copy:before {content: "\f116";}
.icons8-copy-filled:before {content: "\f117";}
.icons8-plus-math:before {content: "\f118";}
.icons8-length:before {content: "\f119";}
.icons8-length-filled:before {content: "\f11a";}
.icons8-clone:before {content: "\f11b";}
.icons8-clone-filled:before {content: "\f11c";}
.icons8-rotate-left:before {content: "\f11d";}
.icons8-unlock:before {content: "\f11e";}
.icons8-private-2-filled:before {content: "\f11f";}
.icons8-pill:before {content: "\f120";}
.icons8-doctors-bag:before {content: "\f121";}
.icons8-horizontal-settings-mixer-filled:before {content: "\f122";}
.icons8-manual-filled:before {content: "\f123";}
.icons8-no-entry-filled:before {content: "\f124";}
.icons8-ethernet-off:before {content: "\f125";}
.icons8-versions:before {content: "\f126";}
.icons8-connected:before {content: "\f127";}
.icons8-stop-filled:before {content: "\f128";}
.icons8-delete:before {content: "\f129";}
.icons8-high-importance:before {content: "\f12a";}
.icons8-circled-up:before {content: "\f12b";}
.icons8-brick-wall-filled:before {content: "\f12c";}
.icons8-cancel:before {content: "\f12d";}
.icons8-computer-monitor:before {content: "\f12e";}
.icons8-computer-monitor-2:before {content: "\f12f";}
.icons8-split-files:before {content: "\f130";}
.icons8-merge-files:before {content: "\f131";}
.icons8-certificate:before {content: "\f132";}
.icons8-windows-client:before {content: "\f133";}
.icons8-horizontal-line:before {content: "\f134";}
.icons8-frequency-f:before {content: "\f135";}
.icons8-up-right:before {content: "\f136";}
.icons8-down-right:before {content: "\f137";}
.icons8-binoculars:before {content: "\f138";}
.icons8-tags:before {content: "\f139";}
