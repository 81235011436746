@use 'sass:map';
@use './palette' as p;
@use './text';

@forward './palette';
@forward './text';

@function get-color($palette, $number: 1) {
  @return map.get(map.get(p.$palettes, $palette), $number);
}

@function get-grey-color($number) {
  @return get-color(grey, $number);
}

@function get-grey-blue-color($number) {
  @return get-color(grey-blue, $number);
}

@function get-grey-purple-color($number) {
  @return get-color(grey-purple, $number);
}

@function get-blue-color($number) {
  @return get-color(blue, $number);
}

@function get-blue-purple-color($number) {
  @return get-color(blue-purple, $number);
}

@function get-purple-color($number) {
  @return get-color(purple, $number);
}

@function get-green-color($number) {
  @return get-color(green, $number);
}

@function get-yellow-color($number) {
  @return get-color(yellow, $number);
}

@function get-orange-color($number) {
  @return get-color(orange, $number);
}

@function get-red-color($number) {
  @return get-color(red, $number);
}

/**
 * Get text color.
 * Categories: primary, secondary, tertiary
 */
@function get-text-color($category: primary) {
  @return map.get(text.$text, $category);
}
