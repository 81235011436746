@use 'theming' as theming;
@use './functions' as fn;

$focused-color: rgba(64, 139, 252, 0.4);
$invalid-color: rgba(183, 55, 45, 0.8);
$disabled-border-color: theming.get-grey-color(600);
$placeholder-color: theming.get-grey-color(600);
$disabled-background-color: theming.get-grey-color(100);
$disabled-color: #bbbbbb;
$input-border-color: #C1C4E1;
$option-hover-background-color: #F5FBFF;

@mixin input-disabled {
  cursor: not-allowed;
  border-color: $disabled-border-color;
  background-color: $disabled-background-color;
  color: $disabled-color;
}

.gc-input-element {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  line-height: 1.6;
  font-size: 14px;
  height: 16px;
  color: theming.get-grey-color(800);
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    color: $placeholder-color;
    padding-right: 10px;
  }
  &:disabled {
    @include input-disabled();
  }
}

.gc-input {
  display: inline-block;
  position: relative;
  text-align: left;
  border: 1px solid theming.get-grey-purple-color(200);
  border-radius: 3px;
  padding: 7px 8px 7px 16px;
  line-height: 16px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.gc-input-focused {
    box-shadow: inset 0 0 0 2px $focused-color;
  }

  &.gc-input-invalid {
    box-shadow: inset 0 0 0 2px $invalid-color;
  }

  &.gc-input-disabled {
    @include input-disabled();
  }
}

.mat-autocomplete-panel {
  background: white;
  border: 1px solid $input-border-color;
  box-shadow: 1px 2px 4px -1px rgba(28, 55, 90, 0.16);
  border-radius: fn.rem(4);
  margin-top: fn.rem(6);

  .mat-option {
    font-size: fn.rem(14);
    line-height: fn.rem(16);
    height: auto;
    padding: fn.rem(12) fn.rem(16);

    &:hover {
      background: $option-hover-background-color;
    }
  }
}

.mat-active, .mat-selected {
  background: $option-hover-background-color;
}
