/*!
	Lato font.
*/
/* Lato (light, regular) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Light.woff2') format('woff2'),
         url('../fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
/* Lato (light, italic) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-LightItalic.woff2') format('woff2'),
         url('../fonts/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
/* Lato (normal, regular) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
         url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
/* Lato (normal, italic) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Italic.woff2') format('woff2'),
         url('../fonts/Lato-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
/* Lato (semibold, regular) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Semibold.woff2') format('woff2'),
         url('../fonts/Lato-Semibold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
/* Lato (semibold, italic) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-SemiboldItalic.woff2') format('woff2'),
         url('../fonts/Lato-SemiboldItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
/* Lato (bold, regular) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
         url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
/* Lato (bold, italic) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
         url('../fonts/Lato-BoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
/* Lato (heavy, regular) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-Heavy.woff2') format('woff2'),
         url('../fonts/Lato-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
/* Lato (heavy, italic) */
@font-face {
    font-family: Lato;
    src: url('../fonts/Lato-HeavyItalic.woff2') format('woff2'),
         url('../fonts/Lato-HeavyItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}