@use 'functions' as fn;

.errors-container {
  display: flex;
  margin-top: fn.rem(8);

  gc-icon {
    margin-right: fn.rem(8);
  }

  .length {
    margin-left: auto;
  }
}
