@use 'functions' as fn;

$scroller-thickness: fn.rem(10);
$scroller-background-color: transparent;
$thumb-color: #dcdcdc;
$thumb-color-hover: #c8c8c8;

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: $scroller-thickness;
}

::-webkit-scrollbar:horizontal {
  height: $scroller-thickness;
}
::-webkit-scrollbar {
  background-color: $scroller-background-color;
}
::-webkit-scrollbar-track {
  background: $scroller-background-color;
}
::-webkit-scrollbar-thumb {
  border-radius: fn.rem(12);
  background: $thumb-color;
  transition: background 100ms ease;
}
::-webkit-scrollbar-thumb:hover {
  background: $thumb-color-hover;
}
