/*
 * Icon Font general
 * Made with love by Icons8 [ https://icons8.com/ ] using FontCustom [ https://github.com/FontCustom/fontcustom ]
 *
 * Contacts:
 *    [ https://icons8.com/contact ]
 *
 * Follow Icon8 on
 *    Twitter [ https://twitter.com/icons_8 ]
 *    Facebook [ https://www.facebook.com/Icons8 ]
 *    Google+ [ https://plus.google.com/+Icons8 ]
 *    GitHub [ https://github.com/icons8 ]
 */


@font-face {
  font-family: "general";
  src: url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.eot");
  src: url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.eot?#iefix") format("embedded-opentype"),
       url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.woff2") format("woff2"),
       url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.woff") format("woff"),
       url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.ttf") format("truetype"),
       url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.svg#general") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "general";
    src: url("../fonts/general_7dc0dbc4cffcf910536dc82e423a2c5b.svg#general") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8, [data-icons8]:before,
.icons8-address-book:before,
.icons8-address-book-2:before,
.icons8-administrative-tools-filled:before,
.icons8-advance:before,
.icons8-appointment-reminders:before,
.icons8-appointment-reminders-filled:before,
.icons8-ascending-sorting:before,
.icons8-asterisk-filled:before,
.icons8-attention:before,
.icons8-audio-wave-filled:before,
.icons8-back:before,
.icons8-bacteria:before,
.icons8-bacteria-filled:before,
.icons8-bar-chart:before,
.icons8-bar-chart-2:before,
.icons8-block:before,
.icons8-block-2:before,
.icons8-bookmark-ribbon:before,
.icons8-bookmark-ribbon-filled:before,
.icons8-box:before,
.icons8-box-2:before,
.icons8-brain-filled:before,
.icons8-bug:before,
.icons8-bulleted-list:before,
.icons8-calendar:before,
.icons8-calendar-1:before,
.icons8-calendar-1-2:before,
.icons8-check-file:before,
.icons8-circled-1:before,
.icons8-circled-1-filled:before,
.icons8-circled-2:before,
.icons8-circled-2--filled:before,
.icons8-circled-3:before,
.icons8-circled-3--filled:before,
.icons8-circled-chevron-down:before,
.icons8-circled-chevron-down-2:before,
.icons8-circled-chevron-left:before,
.icons8-circled-chevron-left-2:before,
.icons8-circled-chevron-right:before,
.icons8-circled-chevron-right-2:before,
.icons8-circled-chevron-up:before,
.icons8-circled-chevron-up-2:before,
.icons8-circled-down-2:before,
.icons8-circled-down-2-2:before,
.icons8-circled-left-2:before,
.icons8-circled-left-2-2:before,
.icons8-circled-right-2:before,
.icons8-circled-right-2-2:before,
.icons8-circled-up-2:before,
.icons8-circled-up-2-2:before,
.icons8-clear-filters:before,
.icons8-clear-filters-filled:before,
.icons8-combo-chart-filled:before,
.icons8-command-line:before,
.icons8-command-line-2:before,
.icons8-copy:before,
.icons8-copy-2:before,
.icons8-coronavirus:before,
.icons8-coronavirus-2:before,
.icons8-dashboard:before,
.icons8-dashboard-filled:before,
.icons8-data-in-both-directions-filled:before,
.icons8-database-filled:before,
.icons8-define-location:before,
.icons8-descending-sorting:before,
.icons8-detective-filled:before,
.icons8-disconnected:before,
.icons8-domain:before,
.icons8-download-from-the-cloud-filled:before,
.icons8-downloading-updates-filled:before,
.icons8-edit:before,
.icons8-edit-filled:before,
.icons8-electrical-filled:before,
.icons8-error:before,
.icons8-error-filled:before,
.icons8-export:before,
.icons8-filter:before,
.icons8-filter-filled:before,
.icons8-fingerprint:before,
.icons8-forward:before,
.icons8-futures-filled:before,
.icons8-glasses:before,
.icons8-graduation-cap:before,
.icons8-help:before,
.icons8-help-2:before,
.icons8-high-importance-filled:before,
.icons8-hips-filled:before,
.icons8-import:before,
.icons8-info:before,
.icons8-info-filled:before,
.icons8-informatics:before,
.icons8-informatics-2:before,
.icons8-inspection:before,
.icons8-inspection-filled:before,
.icons8-installing-updates:before,
.icons8-invisible:before,
.icons8-invisible-filled:before,
.icons8-ip-address:before,
.icons8-layers:before,
.icons8-left:before,
.icons8-level-up:before,
.icons8-linux-client:before,
.icons8-linux-client-filled:before,
.icons8-list-filled:before,
.icons8-magnet:before,
.icons8-magnet-filled:before,
.icons8-map:before,
.icons8-map-filled:before,
.icons8-microscope:before,
.icons8-microscope-filled:before,
.icons8-mind-map:before,
.icons8-mind-map-filled:before,
.icons8-more:before,
.icons8-more-filled:before,
.icons8-more-than-2-filled:before,
.icons8-mute:before,
.icons8-pause-filled:before,
.icons8-planner:before,
.icons8-planner-2:before,
.icons8-plasmid:before,
.icons8-plugin-filled:before,
.icons8-plus:before,
.icons8-plus-2-math:before,
.icons8-refresh:before,
.icons8-report-card:before,
.icons8-report-card-filled:before,
.icons8-restart-filled:before,
.icons8-right:before,
.icons8-rocket:before,
.icons8-rocket-2:before,
.icons8-roller-brush-filled:before,
.icons8-save:before,
.icons8-save-filled:before,
.icons8-services:before,
.icons8-shutdown:before,
.icons8-shutdown-2:before,
.icons8-sign-language-interpretation:before,
.icons8-signpost:before,
.icons8-signpost-2:before,
.icons8-sorting-arrows:before,
.icons8-speaker:before,
.icons8-speedometer:before,
.icons8-speedometer-filled:before,
.icons8-star-filled:before,
.icons8-statistics-filled:before,
.icons8-support-filled:before,
.icons8-switch-filled:before,
.icons8-switch-off:before,
.icons8-switch-on:before,
.icons8-syringe-filled:before,
.icons8-system-information:before,
.icons8-system-task-filled:before,
.icons8-tar:before,
.icons8-tar-2:before,
.icons8-tasks:before,
.icons8-test-tube:before,
.icons8-thin-test-tube-filled:before,
.icons8-todo-list-filled:before,
.icons8-toggle-off:before,
.icons8-toggle-on-filled:before,
.icons8-treatment-plan:before,
.icons8-treatment-plan-filled:before,
.icons8-uninstalling-updates:before,
.icons8-upload:before,
.icons8-upload-to-the-cloud-filled:before,
.icons8-user:before,
.icons8-user-2:before,
.icons8-view-details:before,
.icons8-view-details-2:before,
.icons8-virtual-machine:before,
.icons8-virtual-machine-2:before,
.icons8-visible:before,
.icons8-visible-filled:before,
.icons8-windows-client:before,
.icons8-windows-client-filled:before,
.icons8-zoom-in:before {
  display: inline-block;
  font-family: "general";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-address-book:before { content: "\f17d"; }
.icons8-address-book-2:before { content: "\f17e"; }
.icons8-administrative-tools-filled:before { content: "\f143"; }
.icons8-advance:before { content: "\f104"; }
.icons8-appointment-reminders:before { content: "\f140"; }
.icons8-appointment-reminders-filled:before { content: "\f13f"; }
.icons8-ascending-sorting:before { content: "\f11c"; }
.icons8-asterisk-filled:before { content: "\f15c"; }
.icons8-attention:before { content: "\f1a6"; }
.icons8-audio-wave-filled:before { content: "\f18b"; }
.icons8-back:before { content: "\f192"; }
.icons8-bacteria:before { content: "\f170"; }
.icons8-bacteria-filled:before { content: "\f148"; }
.icons8-bar-chart:before { content: "\f11a"; }
.icons8-bar-chart-2:before { content: "\f181"; }
.icons8-block:before { content: "\f128"; }
.icons8-block-2:before { content: "\f129"; }
.icons8-bookmark-ribbon:before { content: "\f16a"; }
.icons8-bookmark-ribbon-filled:before { content: "\f169"; }
.icons8-box:before { content: "\f175"; }
.icons8-box-2:before { content: "\f176"; }
.icons8-brain-filled:before { content: "\f14f"; }
.icons8-bug:before { content: "\f12b"; }
.icons8-bulleted-list:before { content: "\f11f"; }
.icons8-calendar:before { content: "\f187"; }
.icons8-calendar-1:before { content: "\f188"; }
.icons8-calendar-1-2:before { content: "\f189"; }
.icons8-check-file:before { content: "\f19d"; }
.icons8-circled-1:before { content: "\f193"; }
.icons8-circled-1-filled:before { content: "\f194"; }
.icons8-circled-2:before { content: "\f195"; }
.icons8-circled-2--filled:before { content: "\f196"; }
.icons8-circled-3:before { content: "\f197"; }
.icons8-circled-3--filled:before { content: "\f198"; }
.icons8-circled-chevron-down:before { content: "\f107"; }
.icons8-circled-chevron-down-2:before { content: "\f108"; }
.icons8-circled-chevron-left:before { content: "\f109"; }
.icons8-circled-chevron-left-2:before { content: "\f10a"; }
.icons8-circled-chevron-right:before { content: "\f10d"; }
.icons8-circled-chevron-right-2:before { content: "\f10e"; }
.icons8-circled-chevron-up:before { content: "\f10b"; }
.icons8-circled-chevron-up-2:before { content: "\f10c"; }
.icons8-circled-down-2:before { content: "\f115"; }
.icons8-circled-down-2-2:before { content: "\f116"; }
.icons8-circled-left-2:before { content: "\f112"; }
.icons8-circled-left-2-2:before { content: "\f113"; }
.icons8-circled-right-2:before { content: "\f10f"; }
.icons8-circled-right-2-2:before { content: "\f114"; }
.icons8-circled-up-2:before { content: "\f110"; }
.icons8-circled-up-2-2:before { content: "\f111"; }
.icons8-clear-filters:before { content: "\f1af"; }
.icons8-clear-filters-filled:before { content: "\f1b0"; }
.icons8-combo-chart-filled:before { content: "\f133"; }
.icons8-command-line:before { content: "\f12c"; }
.icons8-command-line-2:before { content: "\f12d"; }
.icons8-copy:before { content: "\f120"; }
.icons8-copy-2:before { content: "\f121"; }
.icons8-coronavirus:before { content: "\f171"; }
.icons8-coronavirus-2:before { content: "\f172"; }
.icons8-dashboard:before { content: "\f13c"; }
.icons8-dashboard-filled:before { content: "\f13b"; }
.icons8-data-in-both-directions-filled:before { content: "\f18c"; }
.icons8-database-filled:before { content: "\f14d"; }
.icons8-define-location:before { content: "\f127"; }
.icons8-descending-sorting:before { content: "\f11b"; }
.icons8-detective-filled:before { content: "\f14b"; }
.icons8-disconnected:before { content: "\f19b"; }
.icons8-domain:before { content: "\f19f"; }
.icons8-download-from-the-cloud-filled:before { content: "\f153"; }
.icons8-downloading-updates-filled:before { content: "\f154"; }
.icons8-edit:before { content: "\f1ac"; }
.icons8-edit-filled:before { content: "\f101"; }
.icons8-electrical-filled:before { content: "\f144"; }
.icons8-error:before { content: "\f162"; }
.icons8-error-filled:before { content: "\f163"; }
.icons8-export:before { content: "\f156"; }
.icons8-filter:before { content: "\f1ad"; }
.icons8-filter-filled:before { content: "\f1ae"; }
.icons8-fingerprint:before { content: "\f12a"; }
.icons8-forward:before { content: "\f191"; }
.icons8-futures-filled:before { content: "\f134"; }
.icons8-glasses:before { content: "\f19c"; }
.icons8-graduation-cap:before { content: "\f16c"; }
.icons8-help:before { content: "\f130"; }
.icons8-help-2:before { content: "\f1a0"; }
.icons8-high-importance-filled:before { content: "\f136"; }
.icons8-hips-filled:before { content: "\f14c"; }
.icons8-import:before { content: "\f155"; }
.icons8-info:before { content: "\f161"; }
.icons8-info-filled:before { content: "\f160"; }
.icons8-informatics:before { content: "\f16d"; }
.icons8-informatics-2:before { content: "\f16e"; }
.icons8-inspection:before { content: "\f146"; }
.icons8-inspection-filled:before { content: "\f145"; }
.icons8-installing-updates:before { content: "\f17f"; }
.icons8-invisible:before { content: "\f190"; }
.icons8-invisible-filled:before { content: "\f18d"; }
.icons8-ip-address:before { content: "\f18a"; }
.icons8-layers:before { content: "\f124"; }
.icons8-left:before { content: "\f106"; }
.icons8-level-up:before { content: "\f118"; }
.icons8-linux-client:before { content: "\f179"; }
.icons8-linux-client-filled:before { content: "\f17a"; }
.icons8-list-filled:before { content: "\f167"; }
.icons8-magnet:before { content: "\f142"; }
.icons8-magnet-filled:before { content: "\f141"; }
.icons8-map:before { content: "\f138"; }
.icons8-map-filled:before { content: "\f137"; }
.icons8-microscope:before { content: "\f14a"; }
.icons8-microscope-filled:before { content: "\f149"; }
.icons8-mind-map:before { content: "\f13a"; }
.icons8-mind-map-filled:before { content: "\f139"; }
.icons8-more:before { content: "\f164"; }
.icons8-more-filled:before { content: "\f165"; }
.icons8-more-than-2-filled:before { content: "\f1a5"; }
.icons8-mute:before { content: "\f1a9"; }
.icons8-pause-filled:before { content: "\f150"; }
.icons8-planner:before { content: "\f185"; }
.icons8-planner-2:before { content: "\f186"; }
.icons8-plasmid:before { content: "\f16f"; }
.icons8-plugin-filled:before { content: "\f14e"; }
.icons8-plus:before { content: "\f199"; }
.icons8-plus-2-math:before { content: "\f19a"; }
.icons8-refresh:before { content: "\f1a1"; }
.icons8-report-card:before { content: "\f15e"; }
.icons8-report-card-filled:before { content: "\f15f"; }
.icons8-restart-filled:before { content: "\f166"; }
.icons8-right:before { content: "\f105"; }
.icons8-rocket:before { content: "\f173"; }
.icons8-rocket-2:before { content: "\f174"; }
.icons8-roller-brush-filled:before { content: "\f103"; }
.icons8-save:before { content: "\f158"; }
.icons8-save-filled:before { content: "\f157"; }
.icons8-services:before { content: "\f19e"; }
.icons8-shutdown:before { content: "\f119"; }
.icons8-shutdown-2:before { content: "\f1b2"; }
.icons8-sign-language-interpretation:before { content: "\f1aa"; }
.icons8-signpost:before { content: "\f125"; }
.icons8-signpost-2:before { content: "\f126"; }
.icons8-sorting-arrows:before { content: "\f117"; }
.icons8-speaker:before { content: "\f1a8"; }
.icons8-speedometer:before { content: "\f13e"; }
.icons8-speedometer-filled:before { content: "\f13d"; }
.icons8-star-filled:before { content: "\f15d"; }
.icons8-statistics-filled:before { content: "\f135"; }
.icons8-support-filled:before { content: "\f100"; }
.icons8-switch-filled:before { content: "\f15b"; }
.icons8-switch-off:before { content: "\f183"; }
.icons8-switch-on:before { content: "\f184"; }
.icons8-syringe-filled:before { content: "\f151"; }
.icons8-system-information:before { content: "\f12f"; }
.icons8-system-task-filled:before { content: "\f1b4"; }
.icons8-tar:before { content: "\f122"; }
.icons8-tar-2:before { content: "\f123"; }
.icons8-tasks:before { content: "\f182"; }
.icons8-test-tube:before { content: "\f16b"; }
.icons8-thin-test-tube-filled:before { content: "\f147"; }
.icons8-todo-list-filled:before { content: "\f168"; }
.icons8-toggle-off:before { content: "\f15a"; }
.icons8-toggle-on-filled:before { content: "\f159"; }
.icons8-treatment-plan:before { content: "\f1b6"; }
.icons8-treatment-plan-filled:before { content: "\f1b8"; }
.icons8-uninstalling-updates:before { content: "\f180"; }
.icons8-upload:before { content: "\f1a3"; }
.icons8-upload-to-the-cloud-filled:before { content: "\f152"; }
.icons8-user:before { content: "\f17b"; }
.icons8-user-2:before { content: "\f17c"; }
.icons8-view-details:before { content: "\f11d"; }
.icons8-view-details-2:before { content: "\f11e"; }
.icons8-virtual-machine:before { content: "\f131"; }
.icons8-virtual-machine-2:before { content: "\f132"; }
.icons8-visible:before { content: "\f18f"; }
.icons8-visible-filled:before { content: "\f18e"; }
.icons8-windows-client:before { content: "\f178"; }
.icons8-windows-client-filled:before { content: "\f177"; }
.icons8-zoom-in:before { content: "\f102"; }
