$_statusMap: (
  basic: rgba(102, 175, 233, 0.6),
  primary: rgba(102, 175, 233, 0.6),
  danger: rgba(236, 95, 95, 0.6),
  warning: rgba(255, 240, 34, 0.6),
  info: rgba(47, 155, 243, 0.6),
  success: rgba(28, 255, 66, 0.6),
);

[nbInput] {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  @each $key, $shadow in $_statusMap {
    &.status-#{$key} {
      color: var(--input-#{$key}-hover-text-color);
      &:focus {
        box-shadow: 0 0 8px $shadow;
        &:hover {
          border-color: var(--input-#{$key}-focus-border-color);
          color: var(--input-#{$key}-focus-text-color);
        }
      }
    }
  }
}
