/*
 * Icon Font Reveal
 * Made with love by Icons8 [ https://icons8.com/ ] using FontCustom [ https://github.com/FontCustom/fontcustom ]
 *
 * Contacts:
 *    [ https://icons8.com/contact ]
 *
 * Follow Icon8 on
 *    Twitter [ https://twitter.com/icons_8 ]
 *    Facebook [ https://www.facebook.com/Icons8 ]
 *    Google+ [ https://plus.google.com/+Icons8 ]
 *    GitHub [ https://github.com/icons8 ]
 */


@font-face {
  font-family: "Reveal";
  src: url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.eot");
  src: url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.woff2") format("woff2"),
       url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.woff") format("woff"),
       url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.ttf") format("truetype"),
       url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.svg#Reveal") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Reveal";
    src: url("../fonts/Reveal_22f188a8b0bdae4b2e032b5b38833838.svg#Reveal") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

[data-icons8]:before,
.icons8-active-directory-filled:before,
.icons8-align-justify:before,
.icons8-attention:before,
.icons8-cancel-filled:before,
.icons8-circled-chevron-left-filled:before,
.icons8-circled-chevron-right-filled:before,
.icons8-cloud:before,
.icons8-collapse-arrow:before,
.icons8-collect:before,
.icons8-csv:before,
.icons8-csv-filled:before,
.icons8-cursor:before,
.icons8-cursor-filled:before,
.icons8-error:before,
.icons8-expand-arrow:before,
.icons8-fast-forward-filled:before,
.icons8-filter:before,
.icons8-filter-filled:before,
.icons8-keyboard:before,
.icons8-keyboard-filled:before,
.icons8-left-filled:before,
.icons8-line-chart:before,
.icons8-link:before,
.icons8-link-filled:before,
.icons8-map:before,
.icons8-map-filled:before,
.icons8-marker-pen:before,
.icons8-marker-pen-filled:before,
.icons8-page-overview-4:before,
.icons8-page-overview-4-filled:before,
.icons8-rewind-filled:before,
.icons8-search:before,
.icons8-search-filled:before,
.icons8-sheets:before,
.icons8-sheets-filled:before,
.icons8-tree-structure-filled:before,
.icons8-undo:before,
.icons8-undo-filled:before,
.icons8-whole-hand:before {
  display: inline-block;
  font-family: "Reveal";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-active-directory-filled:before { content: "\f100"; }
.icons8-align-justify:before { content: "\f101"; }
.icons8-attention:before { content: "\f102"; }
.icons8-cancel-filled:before { content: "\f103"; }
.icons8-circled-chevron-left-filled:before { content: "\f104"; }
.icons8-circled-chevron-right-filled:before { content: "\f105"; }
.icons8-cloud:before { content: "\f106"; }
.icons8-collapse-arrow:before { content: "\f107"; }
.icons8-collect:before { content: "\f108"; }
.icons8-csv:before { content: "\f109"; }
.icons8-csv-filled:before { content: "\f10a"; }
.icons8-cursor:before { content: "\f10b"; }
.icons8-cursor-filled:before { content: "\f10c"; }
.icons8-error:before { content: "\f10d"; }
.icons8-expand-arrow:before { content: "\f10e"; }
.icons8-fast-forward-filled:before { content: "\f10f"; }
.icons8-filter:before { content: "\f110"; }
.icons8-filter-filled:before { content: "\f111"; }
.icons8-keyboard:before { content: "\f112"; }
.icons8-keyboard-filled:before { content: "\f113"; }
.icons8-left-filled:before { content: "\f114"; }
.icons8-line-chart:before { content: "\f115"; }
.icons8-link:before { content: "\f116"; }
.icons8-link-filled:before { content: "\f117"; }
.icons8-map:before { content: "\f118"; }
.icons8-map-filled:before { content: "\f119"; }
.icons8-marker-pen:before { content: "\f11a"; }
.icons8-marker-pen-filled:before { content: "\f11b"; }
.icons8-page-overview-4:before { content: "\f11c"; }
.icons8-page-overview-4-filled:before { content: "\f11d"; }
.icons8-rewind-filled:before { content: "\f11e"; }
.icons8-search:before { content: "\f11f"; }
.icons8-search-filled:before { content: "\f120"; }
.icons8-sheets:before { content: "\f121"; }
.icons8-sheets-filled:before { content: "\f122"; }
.icons8-tree-structure-filled:before { content: "\f123"; }
.icons8-undo:before { content: "\f124"; }
.icons8-undo-filled:before { content: "\f125"; }
.icons8-whole-hand:before { content: "\f126"; }