@use 'functions' as fn;
@use 'mixins' as mx;
@use 'theming' as theming;

.default-button {
  @include mx.default-button();
}

.ghost-button {
  @include mx.default-button();
  color: theming.get-blue-purple-color(500);
  border: unset;

  &:hover {
    background-color: theming.get-grey-color(150);
    border: unset;
  }
  &:active:not([disabled]) {
    background-color: theming.get-blue-purple-color(100);
    border: unset;
  }
}

.primary-button {
  @include mx.default-button();

  background-color: theming.get-blue-purple-color(500);
  border-color: theming.get-blue-purple-color(500);
  color: #fff;

  &:hover {
    background-color: theming.get-blue-purple-color(550);
    border-color: theming.get-blue-purple-color(550);
  }

  &:active:not([disabled]) {
    background-color: #285f8f;
    border-color: #204c72;
  }

}

.danger-button {
  @include mx.default-button();

  background-color: theming.get-red-color(700);
  border-color: theming.get-red-color(700);
  color: #fff;

  &:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
  }

  &:active:not([disabled]) {
    background-color: #c9302c;
    border-color: #ac2925;
  }
}

.danger-button-inverse {
  @include mx.default-button();

  background-color: #fff;
  border-color: #d9534f;
  color: #d9534f;

  &:hover {
    color: #d9534f;
    background-color: #ececec;
    border-color: #c9302c;
  }

  &:active:not([disabled]) {
    background-color: #e6e5e5;
    border-color: #bf2e29;
  }
}

.text-button {
  @include mx.default-button();

  background-color: transparent;
  border-color: transparent;
  color: inherit;

  &:hover {
    background-color: rgba(216, 216, 216, 0.6);
    border-color: transparent;
  }

  &[disabled] {
    color: #979797;
    background-color: transparent;
    border-color: transparent;
  }
}

.default-button, .ghost-button, .primary-button, .danger-button, .danger-button-inverse {
  &[disabled] {
    color: theming.get-grey-color(500);
    background: theming.get-grey-color(200);
    border: unset;
  }
}
