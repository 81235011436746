@use 'functions' as fn;

.inline-alerts-container {
  margin-top: fn.rem(12);

  .inline-alert {
    margin-bottom: fn.rem(10);
  }

  :last-child {
    margin-bottom: 0;
  }
}
