.live-marker {
  animation: fading ease-in-out 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes fading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
