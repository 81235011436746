@use 'functions' as fn;
@use 'theming' as theming;

.ag-root-wrapper {
  border: none !important;
  border-radius: 0;
}

.ag-row-last {
  border-bottom: none !important;
}

.ag-header {
  background-color: transparent !important;
  border-color: var(--ag-header-column-separator-color) !important;
  border-left: solid 1px var(--ag-header-column-separator-color) !important;
  border-right: solid 1px var(--ag-header-column-separator-color) !important;
  border-bottom: none !important;
  z-index: 1;
  box-shadow: 0 2px 3px rgba(160, 176, 212, 0.2);

  :has(+ [col-id="__CONFIRM_ACTION_COLUMN_ID__"]) {
    &:after {
      background-color: transparent !important;
    }
  }
}

.ag-header-cell {
  padding: 0 16px !important;

  &::after {
    height: 100% !important;
    top: 0 !important;
  }

  &:last-child::after {
    width: 0 !important;
  }

  &[col-id="CHECKBOX_SELECTION_CELL_ID"], &[col-id="rowOperations"], &[col-id="rowExpand"] {
    padding-left: 12px !important;

    &::after {
      width: 0 !important;
    }
  }
}

.ag-header-row {
  background-color: #fff;
}

.ag-row-last {
  border-bottom: solid 1px var(--ag-border-color) !important;
}

.ag-center-cols-container {
  user-select: text;
}

.ag-cell {
  border: none !important;
  border-right: solid 1px var(--ag-border-color) !important;
  padding: 0 !important;

  &:last-child {
    border-right: none !important;
  }

  &[col-id="CHECKBOX_SELECTION_CELL_ID"], &[col-id="rowOperations"],  &[col-id="rowExpand"]{
    border-right: none !important;
  }
}

.ag-row-hover {
  .show-on-row-hover {
    display: block !important;
  }
}

.ag-row-odd.ag-row-hover {
  background-color: var(--ag-odd-row-background-color);
}

.ag-row {
  border-left: solid 1px var(--ag-border-color) !important;
  border-right: solid 1px var(--ag-border-color) !important;

  &.ag-row-disable {
    user-select: text;
    color: theming.get-grey-color(500);
  }

  &[row-id='-1']{
    border: 1px solid theming.get-blue-purple-color(550) !important;
    border-radius: 8px;
    overflow: hidden
  }
  // remove border from left sibling column of __CONFIRM_ACTION_COLUMN_ID__ column
  :has(+ [col-id="__CONFIRM_ACTION_COLUMN_ID__"]) {
    border-right-color: transparent !important;
  }
}

.ag-row.ag-row-group-expanded {
  background-color: var(--ag-expanded-row-background-color);
  border-color: var(--ag-expanded-row-border-color) !important;
  box-shadow: 0px -1px var(--ag-expanded-row-border-color);

  .ag-cell {
    border-color: var(--ag-expanded-row-border-color) !important;
  }
}

.ag-cell[col-id="rowExpand"] {
  .ag-cell-wrapper.ag-row-group {
    align-items: center;
    height: 100%;
  }

  .ag-group-contracted svg, .ag-group-expanded svg {
    fill: var(--ag-expansion-icon-color);
    width: fn.rem(25);
    height: fn.rem(20);
    padding-left: fn.rem(5);
  }
}
